import Label from "./Label";

function PortfolioProject({ project }) {
  const imageURL = `${process.env.PUBLIC_URL}/images/project-images-home/${project.thumbnail}`;

  return (
    <div
      className="m-6 relative bg-slate-900 text-white rounded-xl shadow-md overflow-hidden max-w-sm mx-auto"
      style={{ backgroundImage: `url(${imageURL})`, backgroundSize: "cover" }}
    >
      <div className="backdrop-filter backdrop-blur-lg absolute inset-0"></div>
      <div className="p-6 z-10 relative">
        <h2 className="text-2xl font-semibold mb-2">{project.title}</h2>
        <p className="text-gray-300">{project.description}</p>
        <div className="mt-4">
          {project.technologies.map((tech, index) => (
            <Label key={index} text={tech} />
          ))}
        </div>
        {project.link && (
          <div>
            <a
              href={project.link}
              target="_blank"
              rel="noreferrer"
              className="mt-4 inline-block text-blue-200 hover:text-blue-100"
            >
              <img
                className="h-10 w-10 object-contain"
                src={`${process.env.PUBLIC_URL}/images/logos/github.png`}
                alt="View on github"
              />
            </a>
          </div>
        )}
        {project.download && (
          <div>
            <a
              href={project.download}
              target="_blank"
              rel="noreferrer"
              className="mt-4 inline-block"
            >
              <img
                className="h-6 w-8 object-contain"
                src={`${process.env.PUBLIC_URL}/images/icons/download.png`}
                alt="Download"
              />
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

export default PortfolioProject;
