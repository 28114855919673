import Navbar from "../components/Navbar";
import Socials from "../components/Socials";
import ProfileCard from "../components/ProfileCard";

function AboutPage() {
  return (
    <div className="bg-gray-800 pt-12 min-h-screen items-center">
      <Navbar />
      <Socials />

      <ProfileCard
        title="About Me"
        dark={false}
        description="👋 Hey, I'm Harry, AKA Stage almost everywhere you can have a username. I'm a software engineer and osu! tournament organizer.
        I've spent thousands of hours working on open source projects in a variety of languages."
        profileImg={`${process.env.PUBLIC_URL}/images/logos/sez.png`}
        left={true}
      />

      <div className="my-8" />

      <ProfileCard
        title="Development Commissions"
        dark={true}
        description="Need a custom Database & Web API? How about a Discord bot that scales to tens of thousands of servers?
        Maybe a cross-platform CLI utility for your business? Reach out to me for rates!
        I'm always looking for new projects to work on, and I'm happy to work with you to make your idea a reality."
        profileImg={`${process.env.PUBLIC_URL}/images/logos/sp.png`}
        left={false}
      />

      <div className="my-8" />

      <ProfileCard
        title="Tournaments"
        dark={false}
        description="I'm proud to be organizing the largest 5-digit osu! tournament for the second year in a row,
        the 5 Digit World Cup. Each year, my 60+ member staff team and I spend 7 months preparing to host
        thousands of registrants across 60+ countries in a 3-month-long tournament. For 2024, I look forward to leading
        our amazing staff & dev teams while shattering player expectations...again."
        profileImg={`${process.env.PUBLIC_URL}/images/logos/5wc.png`}
        left={true}
      />

      {/* This is a placeholder for balance */}
      <div></div>
    </div>
  );
}

export default AboutPage;
