import React from "react";

function ProfileCard({
  dark = false,
  title,
  description,
  profileImg,
  left = true,
}) {
  const titleTextColor = dark ? "text-indigo-400" : "text-indigo-500";
  const descTextColor = dark ? "text-stone-300" : "text-black-500";
  const bgColor = dark ? "bg-slate-900" : "bg-white";

  return (
    <div
      className={`max-w-md mx-auto ${bgColor} rounded-xl shadow-md overflow-hidden md:max-w-2xl`}
    >
      {left ? (
        <div className="md:flex">
          <div className="md:flex-shrink-0">
            <img
              className="md:h-24 md:w-24 h-18 w-18 m-2 object-cover rounded-full"
              src={profileImg}
              alt="Profile"
            />
          </div>
          <div className="p-8">
            <div
              className={`uppercase tracking-wide text-sm ${titleTextColor} font-semibold`}
            >
              {title}
            </div>
            <p className={`mt-2 ${descTextColor}`}>{description}</p>
          </div>
        </div>
      ) : (
        <div
          className={`md:flex md:items-start md:flex-row-reverse flex flex-col`}
        >
          <div className="md:flex-shrink-0 md:order-none">
            <img
              className="md:h-24 md:w-24 h-18 w-18 m-2 object-cover rounded-full"
              src={profileImg}
              alt="Profile"
            />
          </div>
          <div className="p-8">
            <div
              className={`uppercase tracking-wide text-sm ${titleTextColor} font-semibold`}
            >
              {title}
            </div>
            <p className={`mt-2 ${descTextColor}`}>{description}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfileCard;
