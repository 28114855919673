import { projects } from "../data/projects";
import Navbar from "../components/Navbar";
import PortfolioProject from "../components/PortfolioProject";

function ProjectPage() {
  return (
    <div className="min-h-screen bg-gray-800 text-center pt-12">
      <Navbar />

      <p className="text-2xl font-sans text-gray-200 font-bold">
        Open Source Projects
      </p>
      <p className="text-sm font-sans text-gray-200 italic">
        Thousands of open-source commits since 2019
      </p>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-12 px-6">
        {projects.map((project) => (
          <PortfolioProject key={project.id} project={project} />
        ))}
      </div>
    </div>
  );
}

export default ProjectPage;
