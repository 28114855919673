// src/components/Label.js
export default function Label({ text }) {
  let colorClass;
  switch(text) {
    case 'Docker':
    case 'Jenkins':
      colorClass = 'bg-sky-700';
      break;
    case 'C#':
      colorClass = 'bg-green-400';
      break;
    case 'ASP.NET Core':
    case 'ASP.NET Web API':
      colorClass = 'bg-blue-500';
      break;
    case 'Blazor':
      colorClass = 'bg-blue-400';
      break;
    case 'C++':
      colorClass = 'bg-pink-500';
      break;
    case 'Python':
    case 'NumPy':
      colorClass = 'bg-sky-600';
      break;
    case 'Tensorflow':
      colorClass = 'bg-orange-500';
      break;
    case 'PyTorch':
      colorClass = 'bg-red-500';
      break;
    case 'PostgreSQL':
    case 'MySQL':
    case 'Dapper':
      colorClass = 'bg-rose-500';
      break;
    case '.NET 6':
    case '.NET 7':
      colorClass = 'bg-indigo-500';
      break;
    case 'Entity Framework Core':
      colorClass = 'bg-pink-600';
      break;
    case 'Discord.NET':
      colorClass = 'bg-violet-500';
      break;
    default:
      colorClass = 'bg-slate-600';
  }
  
  return (
    <span className={`inline-block ${colorClass} text-slate-100 text-xs drop-shadow-xl mx-1 px-2 rounded-full uppercase font-bold tracking-wide`}>
      {text}
    </span>
  );
}