import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  // Close the dropdown if the window is resized to be wider than medium
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <nav className="flex items-center justify-between font-sans font-light h-16 text-gray-400 text-center text-md px-6 md:px-12 lg:px-24">
      <Link to="/">
        <div>
          <p className="inline-block font-semibold">stage</p>
          <p className="inline-block font-light">c.xyz</p>
        </div>
      </Link>
      <div className="relative md:hidden">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="focus:outline-none"
        >
          <FaBars />
        </button>
        <div
          className={`transition-all duration-200 ease-out origin-top transform ${
            isOpen ? "scale-y-100" : "scale-y-0"
          } absolute right-0 mt-4 w-40 bg-gray-700 rounded-md shadow-lg z-20`}
        >
          <Link
            to="/about"
            className="block px-4 py-2 text-white hover:bg-gray-600 transition-colors duration-200 rounded-md"
            onClick={() => setIsOpen(false)}
          >
            About
          </Link>
          <Link
            to="/projects"
            className="block px-4 py-2 text-white hover:bg-gray-600 transition-colors duration-200 rounded-md"
            onClick={() => setIsOpen(false)}
          >
            Projects
          </Link>
        </div>
      </div>
      <div className="hidden md:flex">
        <Link
          to="/about"
          className="mx-2 hover:bg-gray-700 hover:text-white px-2 py-1 rounded-md"
        >
          About
        </Link>
        <Link
          to="/projects"
          className="mx-2 hover:bg-gray-700 hover:text-white px-2 py-1 rounded-md"
        >
          Projects
        </Link>
      </div>
    </nav>
  );
}

export default Navbar;
