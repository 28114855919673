export const projects = [
    {
        id: 1,
        title: 'Brigitta',
        description: 'A refreshing Internet Relay Chat client developed for osu! referees.',
        technologies: ['C#', 'ASP.NET Core', 'Blazor', '.NET 6'],
        link: 'https://github.com/hburn7/Brigitta',
        thumbnail: 'IMG_9957.jpg',
        download: 'https://github.com/hburn7/Brigitta/releases/latest'
    },
    {
        id: 2,
        title: 'BanchoSharp',
        description: 'A fully-featured C# library for communicating with osu!Bancho, osu!\'s multiplayer and chat server.',
        technologies: ['C#', '.NET 6'],
        link: 'https://github.com/hburn7/BanchoSharp',
        thumbnail: 'IMG_9967.jpg'
    },
    {
        id: 3,
        title: 'SimpleMem',
        description: 'A simple yet powerful process memory manipulator. Reads and writes external process memory using the Windows API.',
        technologies: ['C#', '.NET 6'],
        link: 'https://github.com/hburn7/SimpleMem',
        thumbnail: 'IMG_9968.jpg'
    },
    {
        id: 4,
        title: 'OthelloAI',
        description: 'A bitboard-based Othello engine that plays against itself or humans using Alpha-Beta pruning and a heuristic evaluation function.',
        technologies: ['C++'],
        link: 'https://github.com/hburn7/OthelloAI',
        thumbnail: 'IMG_9969.jpg'
    },
    {
        id: 5,
        title: 'DeepOthello',
        description: 'A blazing fast python port of OthelloAI that plays against itself or humans using a Monte Carlo tree search. Experiments with reinforcement learning are ongoing. Utilizes NumPy to implement bitboards.',
        technologies: ['Python', 'Tensorflow', 'PyTorch', 'NumPy'],
        link: 'https://github.com/hburn7/DeepOthello',
        thumbnail: 'IMG_9970.jpg'
    },
    {
        id: 6,
        title: 'Kaguya Discord Bot',
        description: '(Retired) Discord bot for all Discord communities, features fun games and helpful utilities. Pioneered "anti-raid", an algorithm that automatically detects and punishes targeted raids on the community via a highly customizable trigger threshold. A very similar feature has been integrated into Discord first-party. Served 5,000+ communities and 300,000+ users.',
        technologies: ['C#', 'Discord.NET', 'ASP.NET Core', 'Entity Framework Core', 'PostgreSQL', 'Docker', 'Jenkins', 'MySQL'],
        link: 'https://github.com/kaguyabot/Kaguya',
        thumbnail: 'IMG_9974.jpg'
    },
    {
        id: 7,
        title: 'The Roundtable II API & Database',
        description: 'Commissioned, fully-featured API that serves as the bridge between TRT2\'s website, Twitch overlay, and custom PostgreSQL database.',
        technologies: ['C#', 'ASP.NET Web API', '.NET 7', 'Dapper', 'PostgreSQL'],
        link: 'https://github.com/hburn7/TRT2API',
        thumbnail: 'IMG_9972.jpg'
    }
];