function Socials() {
    return (<div className="flex justify-center space-x-4 mb-12">
    <a href="mailto:stagecommissions@gmail.com" target="_blank" rel="noreferrer">
      <img
        src={`${process.env.PUBLIC_URL}/images/icons/email.png`}
        alt="Email: stagecommissions@gmail.com"
        className="h-10 w-10"
      />
    </a>
    <a href="https://twitter.com/stageosu" target="_blank" rel="noreferrer">
      <img
        src={`${process.env.PUBLIC_URL}/images/logos/twitter.png`}
        alt="Twitter"
        className="h-8 w-10 mt-1"
      />
    </a>
    <a href="https://github.com/hburn7" target="_blank" rel="noreferrer">
      <img
        src={`${process.env.PUBLIC_URL}/images/logos/github.png`}
        alt="Github"
        className="h-10 w-10"
      />
    </a>
    <a
      href="https://osu.ppy.sh/users/8191845"
      target="_blank"
      rel="noreferrer"
    >
      <img
        src={`${process.env.PUBLIC_URL}/images/logos/osu.svg`}
        alt="Osu"
        className="h-10 w-10"
      />
    </a>
    <a
      href="https://discord.gg/TjH3uZ8VgP"
      target="_blank"
      rel="noreferrer"
    >
      <img
        src={`${process.env.PUBLIC_URL}/images/logos/discord.png`}
        alt="Discord"
        className="h-10 w-10"
      />
    </a>
    <a href="https://twitch.tv/stagecodes" target="_blank" rel="noreferrer">
      <img
        src={`${process.env.PUBLIC_URL}/images/logos/twitch.png`}
        alt="Twitch"
        className="h-8 w-8 mt-1"
      />
    </a>
    <a href="https://kick.com/stage" target="_blank" rel="noreferrer">
      <img
        src={`${process.env.PUBLIC_URL}/images/logos/kick.svg`}
        alt="Kick"
        className="h-10 w-10"
      />
    </a>
  </div>);
}

export default Socials;